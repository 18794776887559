.ingest {
    position: relative;
}

.height20 {
    height: 20px;
}

.thumbnail {
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: #FFFFFF70;
}

.grid-container {
    min-height: 70vh;
    max-width: 100%;
}

.border-radius-5 {
    border-radius: 5px;
}

.logo {
    width: 60%;
    margin: auto;
    text-align: center;
    display: block;
}


.margin-top-10vh {
    margin-top: 10vh;

}

@keyframes blink {
    from { opacity: 1; }
    to { opacity: 0; }
}